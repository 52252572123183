import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import data from "../../content/pages/contact.yaml"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logoSvg from '../images/limpar-logo-white.svg'
import "./contact.css"
import ReCAPTCHA from "react-google-recaptcha";
import heroBg from "../images/limpar-section-background-3-q80.webp"
import axios from "axios";

const ContactPage = () => {
  const [formData, setFormData] = React.useState({
    name: null,
    phone: null,
    email: null,
    message: null,
    consent: false
  })

  const recaptchaRef = React.createRef();

  const [response, setResponse] = React.useState({ message: null, status: null});

  const handleSubmit = e => {
    e.preventDefault();
    console.log(e.target.formName.value);
    if (
      e.target.formName.value
      && e.target.formEmail.value
      && e.target.formPhone.value
      && e.target.formMessage.value
      && e.target.formBasicCheckbox.value) {
      axios.post("https://limpar.gr/form/contact/", {
        name: e.target.formName.value,
        from: e.target.formEmail.value,
        phone: e.target.formPhone.value,
        message: e.target.formMessage.value,
        recaptcha: recaptchaRef?.current?.getValue()
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (response) {
        console.log(response);
        setResponse({
          message: "Ευχαριστούμε για το μήνυμα, θα επικοινωνήσουμε μαζί σας το συντομότερο.",
          status: "success"
        })
      })
      .catch(function (error) {
        console.log(error);
        setResponse({
          message: "Δυστυχώς υπήρξε ένα πρόβλημα με την φόρμα επικοινωνίας, προσπαθήστε ξανά αργότερα ή επικοινωνήστε μαζί μας τηλεφωνικά ή μέσω email",
          status: "danger"
        })
      });
    } else {
      setResponse({
        message: "Παρακαλώ συμπληρώστε όλα τα πεδία και συμφωνήστε με τους όρους χρήσης",
        status: "warning"
      })
    }
  }

  return (
    <Layout
      headerColorMode="dark"
      preFooterPreset="newsletter"
      pageTitle={data.meta.title}
    >
      <section
        style={{
          backgroundImage: `url("${heroBg}")`
        }}
        className="lp-contact-hero lp-dark-hero">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 col-lg-6">
              <h2 className="lp-dotted-title">{data.content.hero.title}</h2>
              <p>{data.content.hero.text}</p>
            </div>
          </div>
          <div className="row lp-contact-form-wrapper">
            <div className="col-12 col-lg-8">
              <div className="row gx-5">
                <div className="col-12 col-lg-4 text-center text-lg-end lp-contact-form-text d-flex align-items-center align-items-lg-end flex-column">
                  <div style={{ maxWidth: 300 }}>
                    <img className="img-fluid mb-3" src={logoSvg} />
                    <p>{data.content.contactDetails.address}</p>
                    <p>{data.content.contactDetails.phone}</p>
                    <p>{data.content.contactDetails.email}</p>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <Form className="lp-contact-form" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Control
                        type="text"
                        placeholder={data.content.form.namePlaceholder}
                        // onChange={ (e) => setFormData((prevState, e) => {setFormData({...prevState, name: e.target.value})})}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhone">
                      <Form.Control
                        type="text"
                        placeholder={data.content.form.phonePlaceholder}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Control
                        type="text"
                        placeholder={data.content.form.emailPlaceholder}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formMessage">
                      <Form.Control
                        as="textarea"
                        type="text"
                        placeholder={data.content.form.messagePlaceholder}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label={data.content.form.consentText}
                      />
                    </Form.Group>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Le5B-AiAAAAAHVXK6sMUUT-Xz0xdzLDoyBqsbuS"
                    />

                  { response?.message &&
                    <div className={`alert alert-${response.status}`} role="alert">
                      {response.message}
                    </div>
                  }
                    <Button
                      variant="white"
                      type="submit"
                      className="mt-2 px-4 lp-styled-btn"
                    >
                      {data.content.form.submitText}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => <Seo title={ data.meta.title } />

export default ContactPage

